import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { Map } from "immutable";

import ModalWrapper from "Components/ModalWrapper";
import InputField from "Components/fields/InputField";
import Button from "Components/Button";
import ContentPaneLayout from "Components/ContentPaneLayout";
import Loading from "Components/Loading";
import Error from "Components/Error";
import CopyableArea from "Components/CopyableArea";
import ButtonWrapper from "Components/ButtonWrapper";
import Heading4 from "Components/styleguide/Heading4";
import InfoDialog from "Components/InfoDialog";

import { goToEnvironment } from "Libs/utils";
import { branch } from "Reducers/environment/actions/branch";

class EnvironmentBranchPane extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      branchName: ""
    };
    this.onBranchNameChange = this.onBranchNameChange.bind(this);
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps && nextProps.errors) {
      this.setState({
        errors: nextProps.errors
      });
    }
  }

  onBranchNameChange(e) {
    const branchName = e.target.value;

    this.setState(() => ({
      branchName
    }));
  }

  render() {
    const {
      environment,
      push,
      organizationId,
      projectId,
      environmentId,
      intl,
      branch,
      isLoading,
      isEnvironmentLoading
    } = this.props;

    const { branchName } = this.state;
    let branchNameToken = "new-branch";
    branchName
      ? (branchNameToken = branchName.replace(
          /(\s|~|\^|:|\?|\*|\[|\\|\/{2,}){1}/g,
          "-"
        ))
      : "";
    const gitCommand =
      "platform branch " + branchNameToken + " " + environmentId;

    return (
      <ModalWrapper
        id="environment-branch-modal"
        shouldCloseOnOverlayClick={true}
        isOpen={true}
        onRequestClose={() =>
          goToEnvironment(push, organizationId, projectId, environmentId)
        }
        title={[
          intl.formatMessage({ id: "branching_from" }) + " ",
          <strong key="name">{environment.title}</strong>
        ]}
        modalClass="modal-medium modal-environment-action modal-environment-branch"
        closeModal={() =>
          goToEnvironment(push, organizationId, projectId, environmentId)
        }
        announceTitle={
          intl.formatMessage({ id: "branching_from" }) + " " + environment.name
        }
      >
        <ContentPaneLayout
          id="environment-branch-modal-body"
          className="modal-body"
        >
          {this.state.errors &&
          (this.state.errors.get("message") ||
            this.state.errors.get("detail")) ? (
            <Error>
              {this.state.errors.get("message") && (
                <p>{this.state.errors.get("message")}</p>
              )}
              {this.state.errors.get("detail") && (
                <p>{this.state.errors.get("detail")}</p>
              )}
            </Error>
          ) : (
            false
          )}
          <p>
            Creating a new branch will create a new branch in the Git repository
            and an exact copy of your environment. Name your branch below to get
            started.
          </p>
          <InputField
            id="environment-branch-name-input"
            label="Name"
            placeholder={intl.formatMessage({
              id: "branch.name.placeholder"
            })}
            onChange={this.onBranchNameChange}
            value={branchName}
          />
          <Heading4 style={{ marginBottom: 16 }}>
            CLI{" "}
            <InfoDialog
              title="Learn more"
              text={`You can also use this terminal command to branch from ${
                environment.title
              }`}
              to="https://docs.platform.sh/gettingstarted/cli.html"
              linkText="Help"
            />
          </Heading4>
          <CopyableArea
            id="environment-branch-cmd"
            content={gitCommand}
            singleLine={true}
          >
            {gitCommand}
          </CopyableArea>
          {isEnvironmentLoading || isLoading ? (
            <Loading />
          ) : (
            <ButtonWrapper className="modal-buttons">
              <Button
                id="environment-branch-save-btn"
                className="primary"
                type="submit"
                aria-label={intl.formatMessage({ id: "branch.button" })}
                onClick={() => {
                  if (branchName) {
                    branch(branchName);
                  }
                }}
              >
                {intl.formatMessage({ id: "branch.button" })}
              </Button>
              <Button
                id="environment-branch-cancel-btn"
                className="secondary"
                type="button"
                aria-label={intl.formatMessage({ id: "cancel" })}
                onClick={() =>
                  goToEnvironment(
                    push,
                    organizationId,
                    projectId,
                    environmentId
                  )
                }
              >
                {intl.formatMessage({ id: "cancel" })}
              </Button>
            </ButtonWrapper>
          )}
        </ContentPaneLayout>
      </ModalWrapper>
    );
  }
}

const mapStateToProps = (state, props) => {
  const environment = state.environment.getIn(
    ["data", props.organizationId, props.projectId, props.environmentId],
    {}
  );

  return {
    environment,
    isEnvironmentLoading: state.environment.get("loading"),
    isLoading: state.branch.get("loading"),
    errors: state.branch.get("errors", new Map())
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  branch: branchName =>
    dispatch(
      branch(props.organizationId, props.projectId, props.environmentId, {
        branchName
      })
    )
});

EnvironmentBranchPane.propTypes = {
  environment: PropTypes.object,
  intl: PropTypes.object,
  errors: PropTypes.object,
  isLoading: PropTypes.bool,
  isEnvironmentLoading: PropTypes.bool,
  push: PropTypes.func,
  branch: PropTypes.func,
  organizationId: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  environmentId: PropTypes.string.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(EnvironmentBranchPane));
